import React, { useEffect, useState } from 'react';
import feather from 'feather-icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';


import './App.css';

import logo1 from './assets/theme_asset/logo/logo.png';
import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import home_bg from './assets/theme_asset/background/home-bg.jpg';

import logo from './assets/theme_asset/logo.png';

function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if token exists
    const token = localStorage.getItem('token');
    if (!token) {
      // If no token exists, navigate to login
      navigate('/login');
    }

    // Replace icons after component is mounted
    feather.replace();
    
    
  }, []);


  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page by changing the URL
    window.location.href = '/login';
  };
  

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
          
  return (
    <div>
      <div className={`offcanvas sidebar-offcanvas offcanvas-start${isSidebarOpen ? ' show' : ''}`} tabIndex={-1} id="offcanvasLeft">
          <div className="offcanvas-header sidebar-header">
          <a  onClick={closeSidebar}>
            <i className="categories-icon"  data-feather="x" />
                  </a>

            <div className="sidebar-logo">
              <img className="img-fluid logo" src={logo} alt="logo" />
            </div>
             
            <div className="balance">
              <img className="img-fluid balance-bg" src={home_bg} alt="auth-bg" />
              <h2>Stockuk</h2>
              <h5>Welcome to stockuk</h5>
            </div>
             
            
          </div>
          <div className="offcanvas-body">
            <div className="sidebar-content">
              <ul className="link-section">
                <li>
                  <Link to="/" className="pages">
                    <i className="sidebar-icon" data-feather="home"  />
                    <h3>Home</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/deposite" className="pages">
                    <i className="sidebar-icon" data-feather="dollar-sign" />
                    <h3>Recharge</h3>
                  </Link>
                </li>
                {/* <li>
                  <Link to="page-list.html" className="pages">
                    <i className="sidebar-icon" data-feather="file-text" />
                    <h3>Theme Pages</h3>
                  </Link>
                </li> */}
                <li>
                  <Link to="/blog" className="pages">
                    <i className="sidebar-icon" data-feather="command" />
                    <h3>News/Articles Section</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/profile" className="pages">
                    <i className="sidebar-icon" data-feather="user" />
                    <h3>Profile</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="pages">
                    <i className="sidebar-icon" data-feather="user" />
                    <h3>About us</h3>
                  </Link>
                </li>
                <li>
                <Link to="#" onClick={handleLogout} className="pages">
                    <i className="sidebar-icon" data-feather="log-out" />
                    <h3>Log out</h3>
                  </Link>
                </li>
              </ul>
              
            </div>
          </div>
        </div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <button className="sidebar-btn" onClick={toggleSidebar}>
              <i className="menu-icon" data-feather="menu" />
            </button>
            <img className="img-fluid logo" src={logo} alt="logo" />
        <Link to="/notification" className="notification">
          <i className="notification-icon" data-feather="bell" />
        </Link>          </div>
        </div>
      </header>
    </div>
  );
}

export default Sidebar;

                 
                