import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';

// Assuming you're using React Router and have a route set up for blog details






import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';
import facebook from './assets/theme_asset/svg/facebook.svg';
import google from './assets/theme_asset/svg/google.svg';


import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';
import auth1 from './assets/theme_asset/authentication/1.svg';
import auth3 from './assets/theme_asset/authentication/3.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";






function OTP1() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const otp = queryParams.get('otp'); // Change 'email' to 'otp' here
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState({
    email: email,
    otp: '',
   
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    // If the input is a file input, set the file to the image field
    setFormData({ ...formData, [name]: value });
    console.log('Updated FormData:', { ...formData, [name]: value });
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
     
      localStorage.setItem('modalMessage2', JSON.stringify({
        title: 'OTP Verifed Successfully',
        content: 'Reset your password you have enter OTP successfully',
      }));
     
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      console.log('FormData to send:', formDataToSend); // Log the formDataToSend
      await axios.post(`${apiBaseUrl}/otp`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data for file upload
        },
      });
      console.log('OTP verified successfully:', formData);
      navigate(`/reset_password1?email=${encodeURIComponent(email)}`);
      // You can add any further actions here, like redirecting the user or showing a success message
    } catch (error) {
      console.error('Error OTP Not Verified:', error);
      setModalMessage({
        title: 'Error',
        content: 'OTP not vaild. Please enter vaild otp.',
      });
      setShowModal(true);
      
      // Handle error, show error message, etc.
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [modalMessage1, setModalMessage] = useState(null);

  useEffect(() => {
    // Retrieve modal message from local storage
    const storedMessage = localStorage.getItem('modalMessage1');
    if (storedMessage) {
      setModalMessage(JSON.parse(storedMessage));
      // Remove modal message from local storage after retrieving
      localStorage.removeItem('modalMessage1');
    }
  }, []);

 
  return (
    <div>
      {modalMessage1 && (
        <Modal message={modalMessage1} closeModal={() => setModalMessage(null)} />
      )}
      <div className="auth-header">
      <div>
              <a href="/profile" style={{ textDecoration: 'none' }}>
                <button style={{
                  padding: '8px 16px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}>X</button>
              </a>
            </div>
        <a href="/forget_password">
          {' '}
          <i className="back-btn" data-feather="arrow-left" />{' '}
        </a>
        <img className="img-fluid img" src={auth3} alt="v1" />
        <div className="auth-content">
          <div>
            <h2>Change your Password</h2>
            <h4 className="p-0">Don’t worry !</h4>
          </div>
        </div>
      </div>
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="custom-container">
          <div className="form-group">
            <h5>To reset your pin, enter OTP send on register email.</h5>
            <label htmlFor="inputusername" className="form-label">
              OTP
            </label>
            <div className="form-input">
              <input
                type="number"
                className="form-control"
                id="otp"
                name="otp"
                placeholder="Enter Your otp"
                onChange={handleChange}

              />
            </div>
            <div className="form-input">
            <input
                type="hidden"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                placeholder="Enter Your email"
                onChange={handleChange}
                />
            </div>
          </div>
          <button type="submit" className="btn theme-btn w-100">
            Submit 
          </button>
        </div>
      </form>
    </div>
  );
}
export default OTP1;
