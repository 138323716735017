import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from 'react-router-dom';

import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import color from './assets/theme_asset/color.png';
import number from './assets/theme_asset/number.png';
import game from './assets/theme_asset/game.jpg';
import ban1 from './assets/theme_asset/ban1.jpg';
import ban2 from './assets/theme_asset/ban2.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';
import './game.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';






function Demo() {

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };
  



const dataChartRec = {
  labels: [
    'Active',
    'Hold',
    'Exit'
  ],
  datasets: [{
    label: 'Recommandation Insights',
    data: recMetrics,
    backgroundColor: [
      '#2b8b64',
      '#d18925',
      'black'
    ],
    hoverOffset: 4
  }]
};


      

     // console.log(apiBaseUrl);



  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
     
      console.log(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  function getStatusClass(status) {
    switch (status) {
      case 'buy':
        return 'bg-green-status';
      case 'short':
        return 'bg-red-status';
      case 'hold':
        return 'bg-yellow-status';
      case 'exit':
        return 'bg-black-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }


  function getBackgroundClass(status) {
    switch (status) {
      case 'buy':
        return 'bg-green';
      case 'short':
        return 'bg-red';
      case 'hold':
        return 'bg-yellow';
      case 'exit':
        return 'bg-black';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }


  function getTrendBackground(status) {
    switch (status) {
      case 'up':
        return 'bg-green-status';
      case 'down':
        return 'bg-red-status';
      case 'side':
        return 'bg-yellow-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }

  function getTrendIcon(status) {
    switch (status) {
      case 'up':
        return 'arrow-up-circle';
      case 'down':
        return 'arrow-down-circle';
      case 'side':
        return 'arrow-right-circle';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }
  
  const [modalMessage3, setModalMessage] = useState(null);
  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem('modalMessage3');
      if (storedMessage) {
        setModalMessage(JSON.parse(storedMessage));
        // Remove modal message from local storage after retrieving
        localStorage.removeItem('modalMessage3');
      }

       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/demo');
    }
    console.log(token)
    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={true} showFooter={true} /> 

       )
    }

   
  
 
    return (
      <div>
          

        {modalMessage3 && (
          <Modal message={modalMessage3} closeModal={() => setModalMessage(null)} />
        )}
       <Sidebar/>

       
       {/* <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/iconsax@1.1.5/css/iconsax.css" integrity="sha384-kJ8Bl2nzBRn1Fls+eWnBEqMWvAqNSMIAd1N2fXk4UuI2V5kyAFLtk4WuIqHg4HS7" crossOrigin="anonymous" /> */}
       <br/>
       
<section className="section-b-space">
  <div className="custom-container">
    <div className="card-box">
      <div className="card-details">
        <div className="d-flex justify-content-between">
          <h5 className="fw-semibold">Total Balance</h5>
          <img src={ellipse} alt="ellipse" />
        </div>
        <h1 className="mt-2 text-white">$ 1,06,786.65</h1>
        <div className="amount-details">
          <div className="amount w-50 text-start">
            <div className="d-flex align-items-center justify-content-start">
            
              <h5>Refferal Link:</h5>
            </div>
            <Link to="#" className="text-white">Link</Link>
          </div>
          {/* <div className="amount w-50 text-end border-0">
            <div className="d-flex align-items-center justify-content-end">
              <img
                className="img-fluid icon"
                src={arrow_up_right}
                alt="up"
              />
              <h5>Expense</h5>
            </div>
            <h3 className="text-white">$ 91,530.32</h3>
          </div> */}
        </div>
      </div>
      <div className="button-container">
        <Link to="/deposite">
        <button className='botton' style={{padding: '10px' , 
        width: '100%' ,
        marginBottom: '20%',
        borderRadius: '20px',
        backgroundColor: 'rgb(234 0 3)',
        border: 'none',
        color: 'white'}}>Deposit</button></Link>
        <Link to='/withdraw'>
        <button style={{ 
          padding: '10px' , 
        width: '100%' ,
        borderRadius: '20px',
        border: 'solid 1px',
        borderColor: 'gray',
        color: 'black'
        }}>Withdraw</button></Link>
      </div>
    </div>
  </div>
</section>


<section style={{justifyContent: 'center' }} className='mb-5'>
  <div className="custom-container">
    <Slider {...settings}>
      <div>
        <img src={ban1} alt='color' style={{ width: '380px', height: '200px', borderRadius:'25px' , padding:'10px'}} />
      </div>
      <div>
        <img src={ban2} alt='number' style={{ width: '380px', height: '200px', borderRadius:'25px', padding:'10px' }} />
      </div>
      <div>
        <img src={number} alt='game' style={{ width: '380px', height: '200px',  borderRadius:'25px', padding:'10px' }} />
      </div>
    </Slider>
  </div>
</section>

<section>
<div className="custom-container">
  <div className='row' style={{textAlign:'center'}}>
    <div className='col-6'>
     <Link to="/game"> <img src={color} alt='color' style={{ width: '150px', height: '150px', borderRadius:'25px'  }} />
     <h1>Games</h1></Link>
    </div>
    <div className='col-6'>
    <Link to="/game"> <img src={number} alt='number' style={{ width: '150px', height: '150px', borderRadius:'25px'  }} />
      <h1>Games</h1></Link>
    </div>
    
  </div>
  </div>
</section>

<section className="panel-space" />
        {/* panel-space end */}
        {/* bottom navbar start */}
       
        {/* bottom navbar end */}
        {/* add money modal start */}
        <Footer/>

</div>
  
  );
  }

  export default Demo;
