import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";



import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';







function Profile() {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])

  


  const fetchData = async () => {
    try {
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  
  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/profile');
    }
    console.log(token)
    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return    <SkeletonLoader showHeader={true} showFooter={true} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (data === null) {
    return null;
  }

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page by changing the URL
    window.location.href = '/login';
  };
  
  
  if(data == null)
    {
      return (
          <SkeletonLoader showHeader={true} showFooter={true} /> 
       )
    }

  
  return (
    <div>

<Sidebar/>
    
  {/* header end */}
  {/* profile section start */}
  <section >
    <div className="custom-container">
      <div className="profile-section">
        <div className="profile-banner">
        <h2 style={{color:'white', paddingTop:'20px'}}>{data.user.name}</h2>
        <h5 style={{color:'white'}}>{data.user.mobile}</h5>
        </div>
       
      </div>
      <ul className="profile-list">
        <li>
          <Link to="/account" className="profile-box">
            <div className="profile-img">
              <i className="sidebar-icon" data-feather="user" />
            </div>
            <div className="profile-details">
              <h4>My Account</h4>
              <img
                className="img-fluid arrow"
                src={arrow}
                alt="arrow"
              />
            </div>
          </Link>
        </li>
        <li>
          <Link to="/change_password" className="profile-box">
            <div className="profile-img">
              <i className="sidebar-icon" data-feather="settings" />
            </div>
            <div className="profile-details">
              <h4>Change Password</h4>
              <img
                className="img-fluid arrow"
                src={arrow}
                alt="arrow"
              />
            </div>
          </Link>
        </li>
        <li>
          <Link to="/deposite_report" className="profile-box">
            <div className="profile-img">
              <i className="sidebar-icon" data-feather="settings" />
            </div>
            <div className="profile-details">
              <h4>Deposite Report</h4>
              <img
                className="img-fluid arrow"
                src={arrow}
                alt="arrow"
              />
            </div>
          </Link>
        </li>
        <li>
          <Link to="/withdraw_report" className="profile-box">
            <div className="profile-img">
              <i className="sidebar-icon" data-feather="settings" />
            </div>
            <div className="profile-details">
              <h4>Withdraw Report</h4>
              <img
                className="img-fluid arrow"
                src={arrow}
                alt="arrow"
              />
            </div>
          </Link>
        </li>
        <li>
          <Link to="/faq" className="profile-box">
            <div className="profile-img">
              <i className="profile-icon" data-feather="help-circle" />
            </div>
            <div className="profile-details">
              <h4>FAQ's</h4>
              <img
                className="img-fluid arrow"
                src={arrow}
                alt="arrow"
              />
            </div>
          </Link>
        </li>
        <li>
          <Link onClick={handleLogout} className="profile-box">
            <div className="profile-img">
              <i className="profile-icon" data-feather="log-out" />
            </div>
            <div className="profile-details">
              <h4>Log Out</h4>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  </section>
  <section className="panel-space" />
        {/* panel-space end */}
        {/* bottom navbar start */}
       
        {/* bottom navbar end */}
        {/* add money modal start */}
        <Footer/>
     
    </div>

    );
}



export default Profile;
