import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';

// Assuming you're using React Router and have a route set up for blog details






import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';
import facebook from './assets/theme_asset/svg/facebook.svg';
import google from './assets/theme_asset/svg/google.svg';


import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';
import auth1 from './assets/theme_asset/authentication/1.svg';
import auth3 from './assets/theme_asset/authentication/3.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";






function Reset_password1() {
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const email = queryParams.get('email');
    const [formData, setFormData] = useState({
      email: email,
      password: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        localStorage.setItem('modalMessage3', JSON.stringify({
          title: 'Password Changed Successfully',
          content: 'Login with new password',
        }));
        console.log(formData);
        await axios.post(`${apiBaseUrl}/reset_password1`, formData);
        console.log('Password Changed successfully:', formData);


        navigate('/');
      } catch (error) {
        console.error('Error password not changed:', error);
        setModalMessage({
          title: 'Error',
          content: 'Some thing went wrong.Please enter correct password and try again later.',
        });
        setShowModal(true);
      }
    };

    const [modalMessage2, setModalMessage] = useState(null);

  useEffect(() => {
    // Retrieve modal message from local storage
    const storedMessage = localStorage.getItem('modalMessage2');
    if (storedMessage) {
      setModalMessage(JSON.parse(storedMessage));
      // Remove modal message from local storage after retrieving
      localStorage.removeItem('modalMessage2');
    }

    
  }, []);


  return (
    <div>
      {modalMessage2 && (
        <Modal message={modalMessage2} closeModal={() => setModalMessage(null)} />
      )}
        <div className="auth-header">
        <div>
              <Link to="/profile" style={{ textDecoration: 'none' }}>
                <button style={{
                  padding: '8px 16px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}>X</button>
              </Link>
            </div>
          <a href="/">
            <i className="back-btn" data-feather="arrow-left" />
          </a>
          <img className="img-fluid img" src={auth1} alt="v1" />
          <div className="auth-content">
            <div>
              <h2>Reset your password</h2>
              <h4 className="p-0">Fill up the form</h4>
            </div>
          </div>
        </div>
  
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="custom-container">
          <div className="form-group">
              <label htmlFor="password" className="form-label">
                Old Password
              </label>
              <div className="form-input">
                <input
                  type="password"
                  className="form-control"
                  id="old_password"
                  name="old_password"
                  placeholder="Enter Your password"
                  value={formData.old_password}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="password" className="form-label">
                New Password
              </label>
              <div className="form-input">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Enter Your password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
            </div>
  
            <button type="submit" className="btn theme-btn w-100">
              Reset Password
            </button>
          </div>
        </form>
      </div>
    );
  }
  
  export default Reset_password1;